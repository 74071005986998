import iconSelectArrow from '@/assets/img/icons/icon_select_arrow_down@2x.png';
import { breakpoint } from '@/helpers/BreakpointHelper';
import styled, { css } from 'styled-components';

const ReadOnlyCss = css`
  pointer-events: none;
  border-color: #e5e6e8;
  opacity: 0.5;
  background-color: #f8f9fa;
`;

const Select = styled.select<{ readOnly?: boolean }>`
  width: 100%;
  height: 56px;
  padding: 0 13px;
  border: solid 1px #e5e6e8;
  appearance: none;
  background: url(${iconSelectArrow}) no-repeat #fff;
  background-size: 24px;
  background-position-y: 50%;
  background-position-x: 97%;
  outline-color: #000;
  ::-ms-expand {
    display: none;
  }

  ${breakpoint(`mobile`)} {
    font-size: 14px;
  }

  ${({ readOnly }) => readOnly && ReadOnlyCss}
`;

export default Select;
